import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-homepage2',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.scss'],
})
export class Homepage2Component implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle(
      'Heritage - Dealer selling new Manufactured Homes and Modular Homes in Washington State'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Showroom in Everett, WA with singlewide, doublewide and triplewide homes open daily.  Shop for best pricing with free shipping anywhere in Washington',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'manufactured modular homes mobile homes factory built homes marlette clayton snohomish county king county whatcom county schult majestic schult and columbia river display models',
    });
  }
}
