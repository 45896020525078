<div class="floorplan-name">
  {{ floorplan.seriesName }} {{ floorplan.modelName }}
</div>
<div class="displaymodel">
  <ng-container class="displaymodel" *ngIf="floorplan.onDisplay">
    Display Model
  </ng-container>
</div>

<div class="caption">
  <a
    routerLink="/floorplans/{{ floorplan.floorplanId }}/{{
      floorplan.seriesName
    }}/{{ floorplan.modelName }}"
  >
    {{ floorplan.floorplanCaption }}
  </a>
</div>

<!-- pattern below was changed to use the routing instead of click event to -->
<!-- <a [routerLink]="" (click)="openFloorplanDialog()">
  <img src="{{ floorplan.thumbnailUrl }}" />
</a> -->
<a
  routerLink="/floorplans/{{ floorplan.floorplanId }}/{{
    floorplan.seriesName
  }}/{{ floorplan.modelName }}"
>
  <img src="{{ floorplan.thumbnailUrl }}" />
</a>

<!-- component to lay out the floorplan specifications is pulled in here -->
<div class="specs-box">
  <app-floorplan-specs-box [floorplan]="floorplan"></app-floorplan-specs-box>
</div>
<!-- <div class="view-pic-button">
  <button mat-flat-button color="primary"  (click)="openPicturesDialog()">
    View Pics
  </button>
</div> -->


<button class="btn btn-border-pop" (click)="openPicturesDialog()">More Info</button>




<ng-container *ngIf="floorplan.customString1">
  <!-- <div class="three-sixty-button">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/tours', floorplan.floorplanDescription]"
      (click)="openTourDialog()"
    >
      3D Tour
    </button>
  </div> -->

  <button class="btn3D btn-border-pop" (click)="openTourDialog()">3D Tour</button>


</ng-container>
