import { FloorplanService } from './floorplan.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

//for SEO, wanted something like this for title
//3 bedroom 2 bathroom Manufactured Home Model 9999 | Heritage Home Center

@Injectable({
  providedIn: 'root',
})
export class FloorplanTitleResolverService implements Resolve<string> {
  constructor(private floorplanService: FloorplanService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): string | Observable<string> | Promise<string> {
    return this.floorplanService
      .getFloorplan(route.paramMap.get('floorplanId'))
      .pipe(
        map((floorplan) => 
            floorplan.bedrooms + ' bedroom ' + floorplan.bathrooms + ' bathroom manufactured model ' + floorplan.modelName + ' ' + floorplan.seriesName + ' by Marlette | Heritage Home Center'
        )
      )
  }
}

//snippet below works and cool way to get single value for floorplanCaption
//but I wanted a title that had concatenation of multiple fields
// return this.floorplanService
// .getFloorplan(route.paramMap.get('floorplanId'))
// .pipe(
//   pluck('floorplanCaption'),
//   map((floorplanCaption) => `${floorplanCaption}`),
// );
