import { FloorplanComponent } from './floorplan/floorplan.component';
import { FloorplansComponent } from './floorplans/floorplans.component';
import { EnergystarComponent } from './homes/manufactured/energystar/energystar.component';
import { SinglewideComponent } from './homes/manufactured/singlewide/singlewide.component';
import { AboutusModule } from './aboutus/aboutus.module';
import { AlaskaComponent } from './homes/manufactured/alaska/alaska.component';
import { ClearanceComponent } from './components/clearance/clearance.component';
import { Homepage2Component } from './components/homepage2/homepage2.component';
import { AdvantagesComponent } from './components/advantages/advantages.component';
// import { FindusComponent } from './components/findus/findus.component';

import { SplashpageComponent } from './components/splashpage/splashpage.component';
import { PicturesComponent } from './components/pictures/pictures.component';
// import { StoriesComponent } from './components/stories/stories.component';
//import { HomepageComponent } from './components/homepage/homepage.component';
//import { ContactusComponent } from './components/contactus/contactus.component';
// import { AboutusComponent } from './aboutus/aboutus.component';
import { DiffsManufacturedComponent } from './components/diffs-manufactured/diffs-manufactured.component';
import { AppComponent } from './app.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { SvghomepageComponent } from './components/svghomepage/svghomepage.component';
//import { NewitemsComponent } from './components/newitems/newitems.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { htmlFiles, legacyUrl } from './route-matcher';

import { TriplewideComponent } from './homes/manufactured/triplewide/triplewide.component';
import { DoublewideComponent } from './homes/manufactured/doublewide/doublewide.component';
import { CustomReuseStrategy } from './services/routeReuse.service';
import { FloorplanTitleResolverService } from './services/floorplan-title-resolver.service';
import { TempoComponent } from './homes/manufactured/tempo/tempo.component';
// import { OnDisplayToursComponent } from './floorplans/on-display-tours/on-display-tours.component';

// each route is a Javascript object is used for Analytics
const routes: Routes = [
  {
    path: 'home',
    component: Homepage2Component,
    data: {
      title: 'Quality Manufactured Homes from Marlette built by Clayton',
    },
  },
  {
    // path: 'display-models',
    // component: OnDisplayComponent,
    // data: {
    //   title:
    //     'Quality Manufactured Homes from Marlette built by Clayton Display Models',
    // },
    path: 'display-models',
    loadChildren: () =>
      import('./displays/displays.module').then((m) => m.DisplaysModule),
    data: {
      title: 'Display Models',
    },
  },
  {
    path: 'floorplans',
    component: FloorplansComponent,
    data: {
      title:
        'Quality Manufactured Homes from Marlette built by Clayton Floorplan Search',
    },
    // path: 'floorplans',
    // loadChildren: () =>
    //   import('./floorplans/floorplans.module').then((m) => m.FloorplansModule),
    // data: {
    //   title:
    //     'Quality Manufactured Homes from Marlette built by Clayton Floorplan Search',
    // },
  },
  {
    path: 'floorplans/:floorplanId/:seriesName/:modelName',
    component: FloorplanComponent,
    title: FloorplanTitleResolverService,
  },

  // {
  //   path: 'floorplans/:floorplanId/:seriesName/:modelName',
  //   loadChildren: () =>
  //     import('./floorplan/floorplan.module').then((m) => m.FloorplanModule),
  // },
  {
    // path: 'aboutus',
    // component: AboutusComponent,
    // data: { title: 'Heritge Home Center Manufactured Homes About Us' },
    path: 'aboutus',
    loadChildren: () =>
      import('./aboutus/aboutus.module').then((m) => m.AboutusModule),
    data: { title: 'Heritage Home Center Manufactured Homes About Us' },
  },
  {
    // path: 'new',
    // component: NewitemsComponent,
    // data: { title: 'Whats New' },

    path: 'new',
    loadChildren: () =>
      import('./newitems/newitems.module').then((m) => m.NewitemsModule),
    data: { title: 'Whats New' },
  },
  {
    // path: 'stories',
    // component: StoriesComponent,
    // data: { title: 'Stories' },
    path: 'stories',
    loadChildren: () =>
      import('./stories/stories.module').then((m) => m.StoriesModule),
    data: { title: 'Stories' },
  },
  {
    // path: 'faq',
    // component: FaqComponent,
    // data: { title: 'Frequently Asked Questions' },
    path: 'faq',
    loadChildren: () => import('./faqs/faqs.module').then((m) => m.FaqsModule),
    data: { title: 'Faqs' },
  },
  {
    path: 'pictures',
    component: PicturesComponent,
    data: { title: 'Pictures' },
  },
  {
    // path: 'findus',
    // component: FindusComponent,
    // data: { title: 'Find Us' },
    path: 'findus',
    loadChildren: () =>
      import('./findus/findus.module').then((m) => m.FindusModule),
    data: { title: 'Find Us' },
  },
  {
    path: 'diffs',
    component: DiffsManufacturedComponent,
    data: { title: 'Differences' },
  },
  {
    path: 'advantages',
    component: AdvantagesComponent,
    data: { title: 'Advantages' },
  },
  {
    path: 'clearance',
    component: ClearanceComponent,
    data: { title: 'Clearance Homes' },
  },
  {
    path: 'manufactured-homes-alaska',
    component: AlaskaComponent,
    data: { title: 'Alaska Manufactured Homes' },
  },
  {
    path: 'energy-efficient-manufactured-homes',
    component: EnergystarComponent,
    data: { title: 'Energy Star Manufactured Homes' },
  },
  {
    path: 'manufactured-home-singlewides',
    component: SinglewideComponent,
    data: { title: 'Single Wide Manufactured by Tempo' },
  },
  {
    path: 'manufactured-home-doublewides',
    component: DoublewideComponent,
    data: { title: 'Doublewide Wide Manufactured by Marlette' },
  },
  {
    path: 'manufactured-home-triplewides',
    component: TriplewideComponent,
    data: { title: 'Triple Wide Manufactured by Marlette' },
  },
  {
    path: 'tempo-by-Marlette',
    component: TempoComponent,
    data: { title: 'Value priced Tempo Singlewide and Doublewide' },
  },
  {
    // path: 'tours/:matterportId',
    // component: OnDisplayToursComponent,
    // data: { title: 'Matterport tours'
    path: 'tours/:matterportId',
    loadChildren: () =>
      import('./tours/tours.module').then((m) => m.ToursModule),
    data: { title: '3D Tour' },
  },
  {
    path: '',
    pathMatch: 'full',
    component: Homepage2Component,
    data: {
      title: 'Quality Manufactured Homes from Marlette built by Clayton',
    },
  },
  /* {
    matcher: (url) => {
      console.log('**** url: ', url);
      debugger;
      return { consumed: url };
    },
    pathMatch: 'full',
    redirectTo: '/',
  }, */
  {
    path: '**',
    redirectTo: '/',
  },
  // { matcher: htmlFiles, component: Homepage2Component },
  // { matcher: legacyUrl, component: AdvantagesComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //initialNavigation: 'enabled', //used for SSR
      // relativeLinkResolution: 'legacy',  //default is 'corrected'
      scrollPositionRestoration: 'enabled', // is default and ensures router link goes to previous scroll position on backward navigtion,

      // Tell the router to use the HashLocationStrategy.
      // useHash: true,
      enableTracing: false, //true will show router events in console
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
