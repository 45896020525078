<!-- this is the Start Here box -->
<!-- line 1, 2 and 3 define the 3 rows with horizontal lines -->

<div class="parent">

  <!-- <a routerLink="/display-models" routerLinkActive="active">
    <div class="line1">
      <div class="start_here">Start Here:</div>
      <div class="visit_our_display">visit our display center</div>

      <img
        class="right_arrow"
        src="../../assets//logos/right-facing-arrow.png"
        alt="right arrow"
      />
      <div class="horizontal_line">-</div>
    </div>
  </a> -->

  <a routerLink="/manufactured-home-singlewides" routerLinkActive="active">
    <div class="line2">
      <div class="start_here">Value:</div>
      <div class="visit_our_display">single wide homes</div>
      <img
        class="right_arrow"
        src="../../assets//logos/right-facing-arrow.png"
        alt="right arrow"
      />
      <div class="horizontal_line">-</div>
    </div>
  </a>

  <a routerLink="/manufactured-home-doublewides" routerLinkActive="active">
    <div class="line2">
      <div class="start_here">Comfy:</div>
      <div class="visit_our_display">double wide homes</div>
      <img
        class="right_arrow"
        src="../../assets//logos/right-facing-arrow.png"
        alt="right arrow"
      />
      <div class="horizontal_line">-</div>
    </div>
  </a>

  <a routerLink="/manufactured-home-triplewides" routerLinkActive="active">
    <div class="line2">
      <div class="start_here">Luxurious:</div>
      <div class="visit_our_display">triple wide homes</div>
      <img
        class="right_arrow"
        src="../../assets//logos/right-facing-arrow.png"
        alt="right arrow"
      />
      <div class="horizontal_line">-</div>
    </div>
  </a>


  <a routerLink="/floorplans" routerLinkActive="active">
    <div class="line2">
      <div class="start_here">Search:</div>
      <div class="visit_our_display">All marlette homes</div>
      <img
        class="right_arrow"
        src="../../assets//logos/right-facing-arrow.png"
        alt="right arrow"
      />
      <div class="horizontal_line">-</div>
    </div>
  </a>

  <a routerLink="/new" routerLinkActive="active">
    <div class="line3">
      <div class="start_here">Explore:</div>
      <div class="visit_our_display">What's New</div>
      <img
        class="right_arrow"
        src="../../assets//logos/right-facing-arrow.png"
        alt="right arrow"
      />
    </div>
  </a>
</div>
