<!-- <app-top-nav></app-top-nav> -->
<app-hero-pic></app-hero-pic>
<app-truck-row></app-truck-row>

<app-homepage-intro></app-homepage-intro>

<app-factorytrained></app-factorytrained>
<app-lets-build-row></app-lets-build-row>
<app-still-have-questions></app-still-have-questions>

<!-- <div class="menu-bar">
  <img
    src="../../../assets/heritage_logo.png"
    class="logoH"
    alt="heritage graphic logo"
  />

  <ul class="nav">
    <li><a href="#">DISPLAY CENTER</a></li>
    <li><a href="#">ALL HOMES</a></li>
    <li><a href="#">CUSTOMER STORIES</a></li>
    <li><a href="#">WHAT'S NEW</a></li>
    <li><a href="#">ABOUT US</a></li>
    <li><a href="#">FIND US</a></li>
    <li><a href="#">FAQ</a></li>
  </ul>
</div>
<div class="chat_with_us">
  <p>Chat with us!</p>
  <img
    src="../../../assets/questions.png"
    alt="questions?  call us 425-353-5464"
  />
</div> -->

<!-- <div class="app-hero-pic-component">
  <app-hero-pic></app-hero-pic>
</div>

<div class="truck-row-component">
  <app-truck-row></app-truck-row>
</div>

<div class="our_team_works_component">
  <app-ourteam></app-ourteam>
</div>

<div class="app-lets-build-row-component">
  <app-lets-build-row></app-lets-build-row>
</div>

<div class="app-still-have-questions-component">
  <app-still-have-questions></app-still-have-questions>
</div> -->
