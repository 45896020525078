<div class="container-hero-pic">
  <img src="../../../../assets/Mask_Group_1@2x.jpg" alt="alt" />

  <div class="title_caption">
    New Manufactured Homes built strong by Marlette Homes
    <div class="subtitle_caption">
      Heritage Homes only sells the top quality Marlette brand in their display
      center. Showing beautifully setup and furnished display models - Singlewide, Doublewide and Triplewide Homes open daily for viewing.<br />
      <br />
      The incredible prices for Marlette Tempo Series - low priced homes starting from
      $59,999 delivered!
    </div>
  </div>
  <div class="view_this_model_home">
    <!-- <img src="../../../../assets/3D_Logo.png" alt="3D Logo" /> -->

    <a [routerLink]="" (click)="open3DTour()">
      Click here to see The Marlette Tempo Series
    </a>
    <div class="click_caption">
      Released new for 2023/24, The Marlette Tempo are value-priced new Singlewide,
      Doublewide and Triplewide homes that include high value options such as
      stainless steel appliances at no extra cost! As with all of our homes,
      prices include delivery, setup and after sales service.
    </div>
  </div>
</div>
