import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperOptions } from 'swiper';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';

import { Media } from '../../models/media';
import { MediaDialogData } from 'src/app/models/media-dialog-data';
import { MediaService } from './../../services/media.service';
import { filter } from 'rxjs/operators';

SwiperCore.use([EffectCoverflow, Pagination]);
@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss'],
})
export class PicturesComponent implements OnInit {
  images!: Media[];
  errorMsg!: string;
  isLoading = false; //used to remove the spinner
  haveMedia: any; //indicator to flag no pics to end user

  constructor(
    private mediaservice: MediaService,
    @Inject(MAT_DIALOG_DATA) private data: MediaDialogData
  ) {}

  // original updated below to enable mat-spinner using isLoading flag
  // ngOnInit(): void {
  //   this.mediaservice.getMediaItems(this.data.mediaId).subscribe(
  //     (images) => (this.images = images),
  //     (error) => (this.errorMsg = error)
  //   );
  // }

  ngOnInit(): void {
    this.isLoading = true;
    console.log(this.isLoading);

    //replace with code to set flag if we dont have any images
    // this.mediaservice.getMediaItems(this.data.mediaId).subscribe(
    //   (images) =>  {
    //     this.images = images;
    //     this.isLoading = false;
    // console.log (this.isLoading);
    //   (error) => (this.errorMsg = error)
    //   });

    //added the this.haveMedia boolean to show a page saying 'hey we have no pics'
    this.mediaservice.getMediaItems(this.data.mediaId).subscribe((images) => {
      this.images = images;
      this.isLoading = false;
      console.log(this.isLoading);
      this.haveMedia = this.images.length == 0 ? false : true;
      (error: string) => (this.errorMsg = error);
    });
  }

  // below is a cool way to supply a list of images for testing
  // images = [1055, 194, 368, 1001, 1015, 1014, 1003, 320, 321].map(
  //   (n) => `https://picsum.photos/id/${n}/700/500`
  // );

  config: SwiperOptions = {
    pagination: true,
    // effect: 'fade',
    effect: 'fade',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 40,
      slideShadows: true,
      stretch: 10,
      depth: 80,
      modifier: 1,
    },
    navigation: true,
    spaceBetween: 10,
  };
}
