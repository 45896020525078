<div class="container-hero-pic">
  <img src="../../assets/Whats-New-Hero.jpg" alt="what new" />
  <div class="caption-hero-pic">Search All Homes</div>
</div>

<div class="on-display-title">
  Explore customizable single wide, double wide, and triple wide manufactured home floorplans at Heritage Homes. Choose from many built-to-order options beyond our display models. Prices include delivery and setup, making your home move-in ready in less than 4 weeks. Free delivery anywhere in WA state. Heritage Homes offers exceptional value and personalized solutions for new manufactured homes.</div>

<div class="format-filters">
  <div class="flex-section">
    <div class="beds">
      <div class="bed-label">Select # of Bedrooms</div>
      <div class="bed-select">
        <ng-container *ngFor="let option of bedroomOptions | keyvalue">
          <label [for]="'bedRoomOption' | append: option.key">
            {{ option.key }}
          </label>
          <mat-checkbox
            class="checkbox-margin"
            (change)="filter()"
            [id]="'bedRoomOption' | append: option.key"
            [(ngModel)]="bedroomOptions[option.key]"
          >
          </mat-checkbox>
        </ng-container>
      </div>
    </div>

    <div class="baths">
      <div class="bath-label">Select # of Bathrooms</div>
      <div class="bath-select">
        <ng-container *ngFor="let option of bathroomOptions | keyvalue">
          <label [for]="'bathRoomOption' | append: option.key">
            {{ option.key }}
          </label>
          <mat-checkbox
            class="checkbox-margin"
            (change)="filter()"
            [id]="'bathRoomOption' | append: option.key"
            [(ngModel)]="bathroomOptions[option.key]"
          >
          </mat-checkbox>
        </ng-container>
      </div>
    </div>

    <div class="floors">
      <div class="floor-label">Select Type of Home</div>
      <div class="floor-select">
        <ng-container *ngFor="let option of floorOptions | keyvalue">
          <label [for]="'floorOption' | append: option.key">{{
            option.key | floors
          }}</label>
          <mat-checkbox
            class="checkbox-margin"
            (change)="filter()"
            [id]="'floorOption' | append: option.key"
            [(ngModel)]="floorOptions[option.key]"
          >
          </mat-checkbox>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- disable slider -->
  <!-- <div class="size">
    <label>Show me Homes smaller than (sq ft): </label>
    <mat-slider
      (change)="filter()"
      thumbLabel="true"
      [min]="minSqft"
      [max]="maxSqft"
      aria-label="units"
      [(ngModel)]="selectedSqft"
    >
    </mat-slider>
    <label>{{ selectedSqft }}</label>
  </div> -->
</div>

<mat-spinner *ngIf="isLoading; else floorPlanListings"></mat-spinner>

<ng-template #floorPlanListings>
  <div class="floorplanlist-container">
    <app-floorplan-box
      *ngFor="let floorplan of filteredHomes"
      [floorplan]="floorplan"
    >
    </app-floorplan-box>
  </div>
</ng-template>

<div class="on-display-title">
  <a routerLink="/display-models"> Show Me Homes On Display </a>
</div>