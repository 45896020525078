<ng-container *ngIf="floorplan$ | async as floorplan; else loading">
  <div class="dialog_close">
    <mat-dialog-actions>
      <button
        mat-icon-button class="close_button"
        color="accent"
        mat-dialog-close
        aria-label="Close Dialog"
        (click)="back()"
      >
        <mat-icon aria-hidden="false" aria-label="Close picture icon"
          >close</mat-icon
        >
      </button>
    </mat-dialog-actions>
  </div>

  <div class="top_line">
    <div class="modelName">
      {{ floorplan.seriesName }} {{ floorplan.modelName }}  {{floorplan.bedrooms}} bedroom {{floorplan.bathrooms}} bathroom, {{floorplan.length }} feet long manufactured home by Marlette
    </div>
    <div class="on_display">
      <ng-container *ngIf="floorplan.onDisplay">
        On Display at Heritage
      </ng-container>
    </div>
  </div>
  <div class="description_text">
    <p>{{ floorplan.floorplanCaption }}</p>
  </div>

  <div class="description_text">
    <p>{{ floorplan.floorplanDescription }}</p>
  </div>

  <div class="image_row">
    <img [src]="floorplan.thumbnailUrl" />
  </div>

  <!-- component to lay out the floorplan specifications is pulled in here -->
  <div class="specs-box">
    <app-floorplan-specs-box [floorplan]="floorplan"></app-floorplan-specs-box>
  </div>

  <br />
  <div class="back_button">
    <!-- <button mat-raised-button color=primary (click)="back()">Go Back</button> -->
    <button class="btn btn-border-pop" (click)="back()">Go back</button>
  </div>

</ng-container>

<ng-template #loading>
  <div>One moment...</div>
</ng-template>
