import { Component, OnInit, Input } from '@angular/core';
import { Floorplan } from '../../models/floorplan';
import { AppInsightsService } from '../../services/app-insights.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PicturesComponent } from '../../components/pictures/pictures.component';

@Component({
  selector: 'app-floorplan-box',
  templateUrl: './floorplan-box.component.html',
  styleUrls: ['./floorplan-box.component.scss'],
})
export class FloorplanBoxComponent implements OnInit {
  @Input() floorplan!: Floorplan;
  matterportId!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private appInsights: AppInsightsService
  ) {}

  openPicturesDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '100vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      mediaId: this.floorplan.mediaId,
    };

    // let componentToDisplay: any = PicturesComponent;
    // if (window.innerWidth < 600) {
    //   componentToDisplay = PicturesComponent;
    //   // componentToDisplay = PicturesmobileComponent;
    // }

    //above replaced with below
    let componentToDisplay: any = PicturesComponent;
    componentToDisplay = PicturesComponent;

    this.appInsights.logPageView(
      'Pics for: ' +
        ' ' +
        this.floorplan.seriesName +
        ' ' +
        this.floorplan.modelName
    );
    // this.appInsights.logEvent('');
    const dialogRef = this.dialog.open(componentToDisplay, dialogConfig);
  }

  //using customString1 as placeholder for the matterport model name
  openTourDialog() {
    this.router.navigate(['/tours', this.floorplan.customString1]);
  }

  // openFloorplanDialog() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = false;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = '100vw';
  //   dialogConfig.maxWidth = '100vw';
  //   dialogConfig.height = '100vh';
  //   dialogConfig.maxHeight = '100vh';
  //   dialogConfig.data = {
  //     thumbnailUrl: this.floorplan.thumbnailUrl,
  //   };

  //   this.appInsights.logPageView(
  //     'Floorplan for: ' +
  //       ' ' +
  //       this.floorplan.seriesName +
  //       ' ' +
  //       this.floorplan.modelName
  //   );
  //   const dialogRef = this.dialog.open(FloorplanpopupComponent, dialogConfig);
  // }

  ngOnInit(): void {}
}
