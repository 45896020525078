<ul class="beautiful_display_homes">
  <li>Huge Selection on Display</li>
  <li>Knowledgeable Sales and Service</li>
  <li>All price ranges and budgets</li>
  <li>Zero markup on options</li>
</ul>

<div class="heritage_creates_more">
  Heritage creates more satisfied homeowners because experienced staff custom
  tailor each home just for you. Check out some of our
  <a routerLink="/stories" routerLinkActive="active">Customer Stories</a> to see
  how you can build your dream home.
</div>
