import { Component, OnInit } from '@angular/core';
import { FloorplanService } from './../../../services/floorplan.service';
import { Floorplan } from '../../../models/floorplan';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-doublewide',
  templateUrl: './doublewide.component.html',
  styleUrls: ['./doublewide.component.scss'],
})
export class DoublewideComponent implements OnInit {
  floorplans!: Floorplan[];
  filteredHomes!: Floorplan[];
  floorOptions: any;
  date = new Date();
  isLoading = false;

  constructor(
    private mobilehomeService: FloorplanService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.title.setTitle(
      'Double Wide Mobile and Manufactured Homes for sale by Heritage Home Center'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'From affordable to luxurious - double wide mobile homes prices starting of around $130K not including delivery',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Double Wide Manufactured Home Floorplans for Marlette Schult, Columiba River Majestic',
    });

    this.isLoading = true;
    this.mobilehomeService.getFloorplans().subscribe((floorplans) => {
      this.floorOptions = Array.from(
        new Set(floorplans.map((home) => home.floors))
      );

      // .sort((a: number, b: number) => a - b)
      // .reduce((acc: any, item) => {
      //   acc[item] = false;
      //   return acc;
      // }, {});

      this.floorplans = floorplans;
      // this.filteredHomes = [...floorplans];
      this.filteredHomes = this.floorplans.filter((home) => home.floors == 2);

      this.isLoading = false;
    });
  } /** nginit */
}
