import { SharedModule } from './shared/shared.module';
import { AboutusModule } from './aboutus/aboutus.module';
import { SwiperModule } from 'swiper/angular';
// import { OfficerowComponent } from './aboutus/officerow/officerow.component';
// import { AboutusFooterComponent } from './aboutus/aboutus-footer/aboutus-footer.component';
// import { FindusComponent } from './components/findus/findus.component';
// import { AboutusComponent } from './aboutus/aboutus.component';
//import { StoryComponent } from './components/story/story.component';
//import { StoriesComponent } from './components/stories/stories.component';
// import { OnDisplayToursComponent } from './components/on-display-tours/on-display-tours.component';
// import { OnDisplayComponent } from './components/on-display/on-display.component';
//import { NewitemsComponent } from './components/newitems/newitems.component';
//import { NewitemComponent } from './components/newitem/newitem.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashpageComponent } from './components/splashpage/splashpage.component';
import { AppInsightsService } from './services/app-insights.service';
import { LandingComponent } from './pages/landing/landing.component';
import { GooglemapComponent } from './components/googlemap/googlemap.component';
import { MobilenavComponent } from './components/mobilenav/mobilenav.component';
import { StarthereComponent } from './components/starthere/starthere.component';
import { DiffsManufacturedComponent } from './components/diffs-manufactured/diffs-manufactured.component';
import { AdvantagesComponent } from './components/advantages/advantages.component';

// from heritageweb2
import { Homepage2Component } from './components/homepage2/homepage2.component';
import { TruckRowComponent } from './components/homepage2/truck-row/truck-row.component';
import { LetsBuildRowComponent } from './components/homepage2/lets-build-row/lets-build-row.component';
import { HeroPicComponent } from './components/homepage2/hero-pic/hero-pic.component';
import { BeautifulDisplayComponent } from './components/homepage2/beautiful-display/beautiful-display.component';
import { FactorytrainedComponent } from './components/homepage2/factorytrained/factorytrained.component';
import { Topnavigation2Component } from './components/homepage2/topnavigation2/topnavigation2.component';
import { Starthere2Component } from './components/homepage2/starthere2/starthere2.component';
import { Bottomnavigation2Component } from './components/homepage2/bottomnavigation2/bottomnavigation2.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material-module';
import { RouterModule } from '@angular/router';
import { HomepageIntroComponent } from './components/homepage2/homepage-intro/homepage-intro.component';
import { TriplewideComponent } from './homes/manufactured/triplewide/triplewide.component';
import { DoublewideComponent } from './homes/manufactured/doublewide/doublewide.component';
import { AlaskaComponent } from './homes/manufactured/alaska/alaska.component';
import { PicturesComponent } from './components/pictures/pictures.component';
import { SinglewideComponent } from './homes/manufactured/singlewide/singlewide.component';
import { EnergystarComponent } from './homes/manufactured/energystar/energystar.component';
import { FloorplanComponent } from './floorplan/floorplan.component';
import { FloorplansComponent } from './floorplans/floorplans.component';
import { AppendPipe } from './pipes/append/append.pipe';
import { FloorsPipe } from './pipes/floors/floors.pipe';
import { FormsModule } from '@angular/forms';
import { TempoComponent } from './homes/manufactured/tempo/tempo.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    // AboutusComponent,
    // FindusComponent,
    // OfficerowComponent,
    // AboutusFooterComponent,
    // OnDisplayComponent,
    // OnDisplayToursComponent,
    // FloorplanpopupComponent,
    // FloorplanBoxComponent,
    FloorplansComponent,
    FloorplanComponent,
    PicturesComponent,
    // StoriesComponent,
    // StoryComponent,
    // NewitemsComponent,
    // NewitemComponent,
    // FloorplanSpecsBoxComponent,
    AppendPipe,
    FloorsPipe,
    // FaqComponent,
    FooterComponent,
    // FaqItemComponent,
    SplashpageComponent,
    LandingComponent,
    GooglemapComponent,
    MobilenavComponent,
    StarthereComponent,
    DiffsManufacturedComponent,
    AdvantagesComponent,
    Homepage2Component, //here on down from heritageweb2
    BeautifulDisplayComponent,
    TruckRowComponent,
    LetsBuildRowComponent,
    HeroPicComponent,
    FactorytrainedComponent,
    Topnavigation2Component,
    Starthere2Component,
    Bottomnavigation2Component,
    PagenotfoundComponent,
    HomepageIntroComponent,
    TriplewideComponent,
    DoublewideComponent,
    AlaskaComponent,
    SinglewideComponent,
    EnergystarComponent,
    TempoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    SwiperModule,
    SharedModule, //shared globally
  ],
  providers: [AppInsightsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
