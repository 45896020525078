<!-- original code replaced below for mat-spinner -->
<!-- <swiper [config]="config">
  <ng-template *ngFor="let image of images" swiperSlide>
    <img [src]="image.mediaUrl" />
  </ng-template>
</swiper> -->

<!-- <div class="dialog_close">
  <mat-dialog-actions>
    <button
      mat-icon-button
      class="close_button"
      color="accent"
      mat-dialog-close
      aria-label="Close Dialog"
    >
      <mat-icon aria-hidden="false" aria-label="Close picture icon"
        >close</mat-icon
      >
    </button>
  </mat-dialog-actions>
</div> -->

<mat-spinner *ngIf="isLoading; else showPictures"></mat-spinner>

<div class="show_pictures">
  <ng-template #showPictures>
    <swiper
      *ngIf="haveMedia; else noPics"
      class="swiper-image"
      [config]="config"
    >
      <ng-template *ngFor="let image of images" swiperSlide>
        <img [src]="image.mediaUrl" />
      </ng-template>
    </swiper>
  </ng-template>
</div>

<ng-template #noPics>
  <lottie-player
    src="https://assets10.lottiefiles.com/packages/lf20_knroiddp.json"
    background="transparent"
    speed="1"
    loop
    autoplay
    class="nopics"
  >
  </lottie-player>
  <div class="mytitle">
    <span
      >Oops! Pictures not quite ready... please call 425-353-5464 for additional
      information on this home</span
    >
  </div>
</ng-template>

<!-- <mat-dialog-actions>
  <button
    mat-icon-button
    color="accent"
    mat-dialog-close
    aria-label="Close Dialog"
  >
    <mat-icon aria-hidden="false" aria-label="Close Picture icon"
      >close</mat-icon
    >
  </button>
</mat-dialog-actions> -->
